<template>
    <van-config-provider :theme="theme">
      <Suspense>
          <router-view/>
      </Suspense>
      <van-tabbar route style="z-index:100" v-if="isView">
        <van-tabbar-item replace to="/main" icon="home-o">Home</van-tabbar-item>
        <van-tabbar-item replace to="/push" icon="guide-o" v-if="certification">Push</van-tabbar-item>
        <van-tabbar-item replace to="/menu" icon="label-o">Menu</van-tabbar-item>
      </van-tabbar>
  </van-config-provider>
</template>

<style lang="less">

:root:root{
  --van-cell-group-background : #ffffff00;
  --van-calendar-day-height : 40px;
}
body{
  background-color: var(--van-background);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.area{
    padding: var(--van-padding-md) var(--van-padding-md) calc(var(--van-padding-md) + env(safe-area-inset-bottom));
    background-color: var(--van-background-2);
}

.van-nav-bar .van-nav-bar__title .van-icon{
  color:var(--van-nav-bar-title-text-color);
}

.van-action-bar{
  justify-content:center;
}
.van-tab{
  border-bottom: 1px solid var(--van-border-color);
}

.basic{
  word-break: break-word;
  color: var(--van-text-color);
  h1{
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
  }
  h2{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
  }
  p{
      font-size: 14px;
      margin-bottom: 8px;
  }
  ul{
      margin-left: 16px;
      list-style: disc;
      li{
          font-size: 14px;
          margin-bottom: 8px;
      }
  }

}

.van-tabs--line .van-tabs__wrap {
  height: var(--van-nav-bar-height) !important;
}

.van-action-bar.van-safe-area-bottom {
  box-shadow: 0 0 10px var(--van-border-color);

}

.body{
  a{
    color: var(--van-text-color);
    text-decoration: underline;
  }
  
}
</style>

<script setup>
  import { ref ,watchEffect } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStorage } from '@vueuse/core';
  const router = useRouter();
  const showName = ['main','push','menu'];
  const isView = ref(false);
  const theme = useStorage('theme', 'light');
  const certification = useStorage('certification', false);
  watchEffect(() => {
    isView.value = showName.includes(router.currentRoute.value.name);
  });
</script>
