import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/MainView.vue')
  },{
    path: '/setting',
    name: 'setting',
    component: () => import('../views/SettingView.vue')
  },{
    path: '/message/:tag',
    name: 'message',
    component: () => import('../views/MessageView.vue')
  },{
    path: '/menu',
    name: 'menu',
    component: () => import('../views/MenuView.vue')
  },{
    path: '/license',
    name: 'license',
    component: () => import('../views/LicenseView.vue')
  },{
    path: '/terms',
    name: 'terms',
    component: () => import('../views/TermsView.vue')
  },{
    path: '/ad',
    name: 'ad',
    component: () => import('../views/AdView.vue')
  },{
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },{
    path: '/push',
    name: 'push',
    component: () => import('../views/PushView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/push' && localStorage.getItem('certification') !== 'true') {
    next('/main');
  } else {
    next();
  }
});

export default router
